import React from "react"

import "../styles/articlePreview.scss"
import { Article } from "../types"
import CenteredContentOverBackground from "./centeredContentOverBackground"

type Props = Article

const ArticlePreview = ({ title, text, image }: Props) => {
  return (
    <div className="ArticlePreview">
      {image ? (
        <CenteredContentOverBackground
          fluidImage={image}
          ratio={0.586}
          className="image"
        >
          <h2 className="background-title">{title}</h2>
        </CenteredContentOverBackground>
      ) : (
        <h2>{title}</h2>
      )}
      <div className="line-clamp">{text}</div>
    </div>
  )
}

export default ArticlePreview
