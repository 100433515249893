import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

import ArticlePreview from "./articlePreview"

import "../styles/articles.scss"

const Articles = () => {
  const { allGhostPost, defaultImage } = useStaticQuery(graphql`
    query {
      allGhostPost(sort: { order: DESC, fields: [published_at] }) {
        edges {
          node {
            featureImageSharp {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            title
            excerpt
          }
        }
      }
      defaultImage: file(relativePath: { eq: "sections/coconut.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="Articles">
      <h1>Articles</h1>
      <div className="article-group">
        {allGhostPost.edges.map(({ node }, index) => (
          <Link to={`/${node.slug}`} key={index} className="no-style">
            <ArticlePreview
              title={node.title}
              text={node.excerpt}
              image={
                node.featureImageSharp?.childImageSharp.fluid ||
                defaultImage.childImageSharp.fluid
              }
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Articles
