import { useStaticQuery, graphql, Link } from "gatsby"
import React from "react"
import keyBy from "lodash/keyBy"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/index.scss"

import CenteredContentOverBackground from "../components/centeredContentOverBackground"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Articles from "../components/articles"
import { sections } from "../shared/sections"

const IndexPage = ({ location }) => {
  const {
    cover,
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "lac long.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "sections" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 800) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `)
  const fluidCoverImage = cover.childImageSharp.fluid
  const filesByName = keyBy(nodes, ({ relativePath }) => relativePath)

  return (
    <Layout className="Index" location={location}>
      <Seo title="Home" />
      <CenteredContentOverBackground
        fluidImage={fluidCoverImage}
        className="cover-photo"
      >
        <h1>Welcome to my website</h1>
      </CenteredContentOverBackground>
      <div className="topics-container">
        {sections.map(({ title, pagePath, imagePath }, index) => (
          <Link to={pagePath} key={index}>
            <CenteredContentOverBackground
              className="topic"
              ratio={0.586}
              fluidImage={filesByName[imagePath].childImageSharp.fluid}
            >
              <h1>{title}</h1>
            </CenteredContentOverBackground>
          </Link>
        ))}
      </div>
      <Articles />
    </Layout>
  )
}

export default IndexPage
