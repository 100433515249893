import React, { FunctionComponent, useEffect, useRef } from "react"
import BackgroundImage, { IBackgroundImageProps } from "gatsby-background-image"

type Props = {
  fluidImage: IBackgroundImageProps["fluid"]
  ratio?: number
  className?: string
}

const CenteredContentOverBackground: FunctionComponent<Props> = ({
  fluidImage,
  children,
  ratio = 1,
  className,
}) => {
  const backgroundImage = useRef(null)
  const coverText = useRef(null)
  const centerCoverText = () => {
    const topOffset =
      backgroundImage.current.selfRef.offsetHeight / 2 -
      coverText.current.offsetHeight / 2
    coverText.current.style.top = `${topOffset}px`
  }
  useEffect(() => {
    centerCoverText()
    window.addEventListener("resize", centerCoverText)
    return () => window.removeEventListener("resize", centerCoverText)
  }, [])

  return (
    <div className={className} style={{ position: "relative" }}>
      <BackgroundImage
        ref={backgroundImage}
        fluid={fluidImage}
        style={{ paddingBottom: `${ratio * 100}%` }}
      />
      <div
        ref={coverText}
        style={{ position: "absolute", width: "100%", textAlign: "center" }}
      >
        {children}
      </div>
    </div>
  )
}

export default CenteredContentOverBackground
